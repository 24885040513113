import React from 'react'
import Layout from '../components/Layout'

export default function needhelp() {
    return (
        <Layout>
        <div className="hero-content">
        <a className="help-button" href="/">Home</a>
        <h1>Who to reach out to? </h1> 
        <p>If you need help with CXMobl, please reach out to the helpdesk. You can reach out via email at helpdesk@courierexpress.net</p> 
        </div>
        </Layout>
    )
}


