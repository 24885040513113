import { Link } from 'gatsby'
import React from 'react'
import "../styles/style.css"
import logo from "../../static/CourierExpresslogo_600px.png"
import menu from "../../static/menu.svg"






export default function Navbar() {
    return (
        
        <div className="container">
        <nav> 
            <img className="logo" src={logo} alt="Courier Express"></img>
            <div className="Links">
            <Link to="/needhelp" className="help">Need help?</Link>
            </div>
               
        </nav>
        </div>
    )
}

